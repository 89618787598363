// Perth Airport

export default {
  settings: {
    disableIframePostMessage: false,
  },
  content: {
    general: {
      clientName: "Perth Airport",
    },
    superFund: `
      <div class="section-title mt-5">
        <h2>
          Australian Super with Perth Airport
        </h2>
      </div>
      <div class="row content mb-5">
        <div class="col-lg-6">
          <p>
            By joining the company Superannuation fund, you benefit from low fees, competitive insurance and a wide range of investment options.
          </p>
          <p class="mt-5">
            This website provides information you may need around the <b>Perth Airport Superannuation Fund</b>. There are helpful super tools and calculators, and information on other services we offer if you require additional support with your financial well-being.
          </p>        
          <div class="col-lg-6 pt-4 pb-1 px-0 text-center text-lg-center">
            <a
              target="_blank"
              href="https://portal.australiansuper.com/login.aspx"
              class="btn-learn-more"
            >
              Log in to Australian Super website
            </a>
          </div>       
        </div>
        <div class="col-lg-6">
          <p>
            <b>Insurances</b>
          </p>
          <ul>
            <li>
              <i class="ri-check-double-line"></i>
              <p><strong>Death & Total Permanent Disability Cover</strong>
              Age Based Cover - refer to PDS</p>
            </li>
            <li>
              <i class="ri-check-double-line"></i>
              <p><strong>Income Protection:</strong>
              Age Based Cover - refer to PDS</p>
            </li>
          </ul>
          <p>
            <b>Investment Options</b>
          </p>
          <ul>
            <li>
              <i class="ri-check-double-line"></i>
              <strong>Default Investment Option:</strong>
              Australian Super Balanced
            </li>
            <li>
              <i class="ri-check-double-line"></i>
              <strong>Investment returns available at:</strong>
              <a href="https://www.australiansuper.com/compare-us/our-performance" target="_blank">Australian Super</a>
            </li>
          </ul>
        </div>
      </div>
    `,
    pdfs: [
      {
        title: "Building your Super Fact Sheet",
        description:
          "View all the different ways you can build your superannuation savings.",
        type: "form",
        url: "Gallagher-Building_Your_Super_Factsheet-2024-2025.pdf",
      },
      {
        title: "Join the Perth Airport fund",
        description:
          "A summary of benefits available to you, including your super choice form.",
        type: "form",
        url: "Perth-Airport-New-Employee-Pack-October23.pdf",
      },
      {
        title: "ATO Rollover Form",
        description:
          "Do you need to rollover your previous super fund to your new super fund?",
        type: "form",
        externalUrl:
          "https://www.ato.gov.au/Forms/Request-for-rollover-of-whole-balance-of-super-benefits-between-funds---Instructions/#Howtogettheform",
      },
      {
        title: "Change your work rating",
        description:
          "If your role at work changes, please make sure you notify AustralianSuper by using this form.",
        type: "form",
        url: "Change-your-work-rating.pdf",
      },
      {
        title: "Australian Super forms",
        description: "View Australian Super forms.",
        type: "form",
        externalUrl:
          "https://www.australiansuper.com/tools-and-advice/forms-and-fact-sheets",
      },
      {
        title: "Increase your Income Protection after a salary increase",
        description:
          "To ensure your income protection remains in line with your earnings, please advise AustralianSuper of your change.",
        type: "form",
        url: "Increase-your-IP-cover-after-a-salary-increase.pdf",
      },
      {
        title: "Insurance Guide",
        description: "",
        type: "info",
        externalUrl:
          "https://www.australiansuper.com/tools-and-advice/forms-and-fact-sheets?t=3",
      },
      {
        title: "First Home Super Saver Scheme",
        description: "",
        type: "info",
        externalUrl:
          "https://www.australiansuper.com/superannuation/grow-your-super/first-home-super-saver",
      },
      {
        title: "Add to your Super",
        description: "",
        type: "info",
        externalUrl:
          "https://www.australiansuper.com/tools-and-advice/calculators/super-contributions-calculator",
      },
    ],
    // optIn: "",
    teamMembers: [
      {
        teamMemberPic: "phil.png",
        name: "Phil Mills",
        phoneHref: "0862508399",
        phone: "08 6250 8399",
        email: "phil_mills@ajg.com.au",
        social: "https://au.linkedin.com/pub/philip-mills/67/701/268",
      },
      {
        teamMemberPic: "natasha.png",
        name: "Natasha Van",
        phoneHref: "1300557782",
        phone: "1300 557 782",
        email: "super-service@ajg.com.au",
      },
    ],
    footerClientName: "Perth Airport",
  },
  superscore: false,

  analytics: {
    gacode: "",
    gaprod: "",
  },
};
